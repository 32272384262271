import { Helmet } from "react-helmet";

const Contact = () => {
  return (
    <div className="contact-me">
      <Helmet>
        <title>Contact nikosid</title>
        <meta name="description" content="Contact with nikosid." />
      </Helmet>
      <h1>Contact me</h1>
      <div className="contact-me__description">
        I read everything I receive except for spam. If I find an email
        interesting, I'll respond.
      </div>
      <div className="contact-me__block">
        <div className="contact-me__line">
          <div className="contact-me__subtitle">mail:</div>
          <div className="contact-me__value">
            <a href="mailto:nikosid@gmail.com">nikosid@gmail.com</a>
          </div>
        </div>
        <div className="contact-me__line">
          <div className="contact-me__subtitle">telegram:</div>
          <div className="contact-me__value">
            <a href="https://t.me/nikosid" target="_blank" rel="noreferrer">
              @nikosid
            </a>
          </div>
        </div>

        <div className="contact-me__line">
          <div className="contact-me__subtitle">facebook:</div>
          <div className="contact-me__value">
            <a
              href="https://www.facebook.com/nikosid.ua"
              target="_blank"
              rel="noreferrer"
            >
              Yuriy Chernichenko
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
