import { Helmet } from "react-helmet";

const About = (props) => {
  return (
    <div className="content">
      <Helmet>
        <title>About nikosid</title>
        <meta name="description" content="Information about nikosid." />
      </Helmet>
      <h1>This page is about real me</h1>
      <h2>This is me</h2>
      <p>Hi! My name is Yuriy Chernichenko.</p>
      <p>I was born in Ukraine in the city of Nikopol in 1984.</p>
      <p>Now i live in Bratislava, Slovakia.</p>
      <h2>I love my family</h2>
      <p>
        The most precious thing I have is my wife and my sons. I also really
        love parents, my sister and her daughters.
      </p>
      <h2>I love my job</h2>
      <p>
        I have been working in Citysites since it was founded, i.e. since 2008.
      </p>
      <p>
        I also have several of my own IT projects, and some of them no longer
        exist.
      </p>
      <p>I am very happy to work with so professional people.</p>
      <p>I love smart and capable people, especially smart ones.</p>
      <h2>I love my friends.</h2>
      <p>
        I have friends in many cities and countries, all of which are associated
        with various memories. I see some friends every weekend and some I have
        not seen for 10 years, but they are all important to me.
      </p>
      <h2>Hobbies</h2>
      <h3>Sport</h3>
      <p>
        At the amateur level, I play football, badminton, table tennis, ride a
        bike, sometimes I go to the gym, less often than necessary.
      </p>
      <h3>Computer games</h3>
      <p>
        I play only two games: Heroes 3, Counter-Strike. The rest is torment in
        order to play something interesting to my son. But I teach him to play
        heroes and counter-strike ;)
      </p>
      <h3>Blogging</h3>
      <p>
        Before moving to Slovakia, I watched a video of various immigrant
        bloggers from different countries and this helped me a lot. After moving
        I also decided to help people and created{" "}
        <a
          href="https://www.youtube.com/channel/UCik1Cdt1X_oS0ZR9AQpLoog"
          target="_blank"
          rel="noreferrer"
        >
          my YouTube channel
        </a>
        .
      </p>
      <h3>Reading</h3>
      <p>
        I need to read more, but I do not have such a habit, so I do not read as
        many books as I need.
      </p>
      <p>
        In the moment my favorite book is Sapiens: A Brief History of Humankind
        by Yuval Noah Harari.
      </p>
      <p>I am also interested in psychology and philosophy.</p>
    </div>
  );
};

export default About;
