import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Home = (props) => {
  return (
    <div className="content">
      <Helmet>
        <title>Nice to meet you, I'm nikosid</title>
        <meta name="description" content="General information about nikosid." />
      </Helmet>
      <h1>So hello</h1>
      <p>You have visited my personal page.</p>
      <p>
        If you write to me what you expected to see here, then maybe next time
        that is what you will see.
      </p>
      <p>In the meantime, I wish you a pleasant day and a happy life.</p>
      <p>I am always happy to help if I can.</p>
      <p>
        If you don't know who I am you can read{" "}
        <Link to="/about">my about page</Link>.
      </p>
      <p>
        If you want to contact me you can contact me by using my contacts on{" "}
        <Link to="/contact">the contact page</Link>.
      </p>
    </div>
  );
};

export default Home;
