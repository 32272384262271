import { useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import "./App.css";
import Header from "./components/Layout/Header";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";

function App() {
  const [isDarkTheme, setIsDarkTheme] = useState(false);

  const themeToggleHandler = (event) => {
    setIsDarkTheme((isDark) => {
      const newThemeIsDark = !isDark;

      if (newThemeIsDark === true) {
        setDarkTheme();
      } else {
        setLightTheme();
      }

      return newThemeIsDark;
    });
  };

  useEffect(() => {
    const storedIsDarkTheme = localStorage.getItem("isDarkTheme") === "1";
    if (storedIsDarkTheme) {
      setDarkTheme();
    } else {
      setLightTheme();
    }
  }, []);

  const setDarkTheme = () => {
    document.body.classList.remove("light-theme");
    document.body.classList.add("dark-theme");
    setIsDarkTheme(true);
    localStorage.setItem("isDarkTheme", 1);
  };

  const setLightTheme = () => {
    document.body.classList.remove("dark-theme");
    document.body.classList.add("light-theme");
    setIsDarkTheme(false);
    localStorage.removeItem("isDarkTheme");
  };

  return (
    <div className="container">
      <Header isDarkTheme={isDarkTheme} onToggleTheme={themeToggleHandler} />
      <main className="main">
        <Switch>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </main>
      <footer className="footer">
        <div className="footer__copyright">nikosid &copy; 2021</div>
      </footer>
    </div>
  );
}

export default App;
